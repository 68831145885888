@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700&display=swap");
@import "~normalize.css";
@import "~@brite-inc/ui-components/dist/static/css/theme";

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    line-height: 1.5;
    color: var(--text-color-default);
    font-size: 16px;
}

a {
    text-decoration: none;
    color: var(--link-color);
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 8px;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
}

p,
ol,
ul {
    margin-bottom: 16px;
    margin-top: 0;
}

button,
select,
input,
textarea {
    line-height: 1.5;
}
